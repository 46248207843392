<template>
    <section id="empresa" class="empresa">
        <div class="interface"> 
            <h2 class="titulo lang-pt">Quem <span >Somos</span></h2> 
            <h2 class="titulo lang-en" style="display: none;">Who <span>We Are</span></h2>
        </div>    
            <div class="flex"> 
                <div class="img-empresa"> 
                    <img src="../assets/image/sobreFREEXAUTO.jpg" alt="">
                </div>
                <div class="txt-empresa" id="empresa-content">
                    <p class="lang-pt">
                    Criada em 2001, com o objetivo de responder às necessidades do mercado automóvel, a Freexauto criou um novo conceito no setor da reparação, manutenção e venda de automóveis inovadores na região.

                    Dotada de um equipamento de alta tecnologia e de uma equipa técnica e comercial altamente qualificada e responsável, a Freexauto tornou-se no melhor parceiro para os clientes que exigem garantia de qualidade e rapidez.

                    No mesmo espaço encontra ainda um stand com uma variada gama de automóveis, loja de peças e acessórios multimarcas com um alargado stock a preços sempre competitivos.

                    No nosso Stand, disponibilizamos também, de várias marcas e modelos de viaturas 100% eléctricas, a preços e qualidade surpreendentes, pensando em si, na sua segurança e bem estar, e claro, na redução de emissões globais de gases com o efeito de estufa, com o objetivo de "arrefecer a terra até 1ºC".

                    Visite-nos!
                    </p>

                    <h3 class="lang-pt"><p>FREEXAUTO SEMPRE A CUIDAR DA SUA SEGURANÇA!</p></h3>
                    <!-- <p class="lang-en">
                    Created in 2001, with the aim of responding to the needs of the automotive market, Freexauto has created a new concept in the sector of repair, maintenance and sale of innovative cars in the region.

                    Equipped with high-tech equipment and a highly qualified and responsible technical and commercial team, Freexauto has become the best partner for customers who demand quality assurance and speed.

                    In the same space you will also find a stand with a wide range of cars, a multi-brand parts and accessories store with a wide stock at always competitive prices.

                    At our Stand, we also offer several brands and models of 100% electric vehicles, at surprising prices and quality, thinking of you, your safety and well-being, and of course, the reduction of global greenhouse gas emissions, with the aim of "cooling the earth to 1ºC".

                    Visit us!
                    </p>
                    <h3 class="lang-en"><p>FREEXAUTO ALWAYS TAKING CARE OF YOUR SAFETY!</p></h3> -->
                </div>
            </div>
    </section>
</template>
<script>
export default {
    
}
</script>

<style >

    section.empresa{
        padding: 40px 4%;
    }

    .empresa .txt-sobre{
        color: var(--cor-preto);
        
    }

    section.empresa .flex{
        align-items: center;
        gap: 60px;
    } 
    .empresa .txt-empresa h2{
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    .empresa .txt-empresa p{
        margin: 20px 0;
        text-align: justify;    
        line-height: 1.6;
    }

    .empresa .img-empresa { 
        padding: 40px 4%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px; 
    }

    .empresa .img-empresa img {
        width: 500px; 
        height: auto;
        border-radius: 10px;
    }

    .empresa h3{
       /* color: var(--cor-vermelho); */
    }


    
</style>