<template>
    <footer>
        <div class="footer-container">
            <div class="footer-section">
                <h3 class="lang-pt">Sobre Nós</h3>
                <h3 class="lang-en">About Us</h3>
                <p class="lang-pt">Informações sobre a empresa.</p>
                <p class="lang-en">Information about the company.</p>
            </div>
            <div class="footer-section">
                <h3 class="lang-pt">Links Rápidos</h3>
                <h3 class="lang-en">Quick Links</h3>
                
                <ul>
                    <li class="lang-pt"><a href="#empresa">Empresa</a></li>
                    <li class="lang-en"><a href="#empresa">Enterprise</a></li>
                    <li class="lang-pt"><a href="#novidades">Novidades</a></li>
                    <li class="lang-en"><a href="#novidades">Novelties</a></li>
                    <li class="lang-pt"><a href="#servicos">Serviços</a></li>
                    <li class="lang-en"><a href="#servicos">Services</a></li>
                    <li class="lang-pt"><a href="#veiculos">Veículos</a></li>
                    <li class="lang-en"><a href="#veiculos">Vehicles</a></li>
                    <li class="lang-pt"><a href="#contato">Contato</a></li>
                    <li class="lang-en"><a href="#contato">Contact</a></li>
                </ul>
            </div>
            <div class="footer-section">
                <h3 class="lang-pt">Contate-nos</h3>
                <h3 class="lang-en">Contact Us</h3>
                <p class="lang-pt">Email: Standfreexauto@gmail.com</p>
                <p class="lang-en">Email: Standfreexauto@gmail.com</p>
                <p class="lang-pt">Telefone: (+238) 9874687</p>
                <p class="lang-en">Phone: (+238) 9874687</p>
                <div class="social-media">
                    <a target="_blank" href="https://www.facebook.com/freexautocv.lda">Facebook</a>
                    <a target="_blank" href="https://www.instagram.com/freexautocaboverde/">Instagram</a>
                    <a target="_blank" href="https://web.whatsapp.com/send?phone=+2389874687">WhatsApp</a>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <p class="lang-pt">&copy; 2024 Empresa. Todos os direitos reservados.</p>
            <p class="lang-en">&copy; 2024 Company. All rights reserved.</p>
        </div>
    </footer>
</template>
<script>
export default {
    
}
</script>
<style>
    
</style>