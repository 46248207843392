import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Icon } from '@iconify/vue';
// import Vue from vue;
// import { castToVueI18n } from 'vue-i18n';

// Vue.use(castToVueI18n);

// const messages = {
//     en: {
//       message: {
//         hello: 'hello world'
//       }
//     },
//     fr: {
//       message: {
//         hello: 'Bonjour le monde'
//       }
//     }
//     // Adicione mais idiomas conforme necessário
//   };

//   const I18N = new VueI18n({
//     locale: 'en', // set locale
//     messages, // set locale messages
//   });

  // new Vue({
  //   // i18n,
  // }).$mount('#app');


createApp(App)
.use(store)
.use(router)
.component('Icon', Icon)
.mount('#app')
