<template>
    <div class="slider">
        <div class="slides" style="display: flex; transition: transform 1s ease;">
            <div class="slide" style="min-width: 100%; box-sizing: border-box;">
                <img src="../assets/image/mercedes_pagina_inicial/mercedes-benz.webp" alt="Image 1" style="width: 100%; display: block;">
            </div>
            <div class="slide" style="min-width: 100%; box-sizing: border-box;">
                <img src="../assets/image/mercedes_pagina_inicial/INTERIOR (1).avif" alt="Image 2" style="width: 100%; display: block;">
            </div>
            <div class="slide" style="min-width: 100%; box-sizing: border-box;">
                <img src="../assets/image/mercedes_pagina_inicial/2022-mercedes-benz.jpg" alt="Image 3" style="width: 100%; display: block;">
            </div> 
        </div>
    </div>
</template>
<script>
    let currentIndex = 0;

    function showNextSlide() {
        const slides = document.querySelector('.slides');
        const totalSlides = document.querySelectorAll('.slide').length;
        currentIndex = (currentIndex + 1) % totalSlides;
        const newTransformValue = `translateX(-${currentIndex * 100}%)`;
        slides.style.transform = newTransformValue; // Corrigido aqui
    //    slides.transform = newTransformValue;
    }

    setInterval(showNextSlide, 3000);
    export default {

        
    }    
</script>

<style >
    .slider{
        width: 100%; 
        overflow: hidden; 
        border: 2px solid #ddd; 
        border-radius: 10px; 
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .slide{
        display: flex; 
        transition: transform 1s ease;
    }
    .slide{
        width: 100%;
        display: block;
    }  
</style>