<template>
   <section id="marca" class="novos-carros" style="min-height: 90vh;">
        <div class="interface">
            <h2 class="titulo lang-pt"><span>{{ marca }}</span></h2>
            <h2 class="titulo lang-en" style="display: none;">Our <span>Updates</span></h2>
        </div>
        <div class="grid" >
            <div v-for="carro in carros" :key="carro.id" class="novoCarro" >
                <div class="carro-box" style="max-width: 20.5em;">
                    <div id="novidades1-content " >
                        <div class="img-container" >
                            <img :src="carro.img" :alt="carro.nome">
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <h3 class="lang-pt">{{ carro.nome }}</h3>
                            <h3 class="lang-en">LEAP MOTORS T03</h3>
                            <p v-if="carro.estado" style="padding: 0px;margin:0" class="lang-pt"><strong>Estado:</strong>{{ carro.estado }}</p>
                            <p v-if="carro.camera" style="padding: 0px;margin:0" class="lang-pt"><strong>Câmera:</strong>{{ carro.camera }}</p>
                            <p v-if="carro.modelosDisponiveis" style="padding: 0px;margin:0" class="lang-pt"><strong>Modelos Disponiveis:</strong>{{ carro.modelosDisponiveis }}</p>
                            <p v-if="carro.tecto" style="padding: 0px;margin:0" class="lang-pt"><strong>Tecto:</strong>{{ carro.tecto }}</p>
                            <p v-if="carro.bateria" style="padding: 0px;margin:0" class="lang-pt"><strong>Bateria:</strong>{{ carro.bateria }}</p>
                            <p v-if="carro.carregador" style="padding: 0px;margin:0" class="lang-pt"><strong>Carregador:</strong>{{ carro.carregador }}</p>
                            <p v-if="carro.caixa" style="padding: 0px;margin:0" class="lang-pt"><strong>Caixa:</strong>{{ carro.caixa }}</p>
                            <p v-if="carro.motor" style="padding: 0px;margin:0"class="lang-pt"><strong>Motor:</strong> {{ carro.motor }}</p>
                            <p v-if="carro.cabine" style="padding: 0px;margin:0"class="lang-pt"><strong>Cabine:</strong> {{ carro.cabine }}</p>
                            <p v-if="carro.potencia" style="padding: 0px;margin:0" class="lang-pt"><strong>Potência:</strong> {{ carro.potencia }}</p>
                            <p v-if="carro.transmissao" style="padding: 0px;margin:0" class="lang-pt"><strong>Transmissão:</strong> {{ carro.transmissao }}</p>
                            <p v-if="carro.cilindrada" style="padding: 0px;margin:0" class="lang-pt"><strong>Cilindrada:</strong> {{ carro.cilindrada }}</p>
                            <p v-if="carro.capacidadeBateria"  style="padding: 0px;margin:0" class="lang-pt"><strong>Capacidade da bateria:</strong> {{ carro.capacidadeBateria }}</p>
                            <p v-if="carro.autonomia" style="padding: 0px;margin:0" class="lang-pt"><strong>Autonomia:</strong> {{ carro.autonomia }}</p>
                            <p v-if="carro.carateristica" style="padding: 0px;margin:0" class="lang-pt"><strong>caraterística:</strong> {{ carro.carateristica }}</p>
                            <p v-if="carro.preco1" style="padding: 0px;margin:0" class="lang-pt"><strong>Preço:</strong> {{ carro.preco1 ?? carro.preco }}</p>
                            <p v-if="carro.preco2" style="padding: 0px;margin:0" class="lang-pt"><strong>Preço:</strong> {{ carro.preco2 }}</p>
                            <p v-if="carro.preco" style="padding: 0px;margin:0" class="lang-pt"><strong>Preço:</strong> {{ carro.preco }}</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-pt"><strong>Inclui um carregador doméstico</strong></p> -->
                            <p v-if="marca == 'acessorio'" class="btn-acessorio"style="padding: 0px;margin:0"><a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=Standfreexauto@gmail.com&su=Informações sobre os acessórios">Enviar email</a></p>
                            <!-- <FlipCard v-for="car in eletrico" :key="car.nome" :car="car" /> -->
                        </div>
                </div>
                </div>               
            </div>

            <div v-if="carros.length === 0" class="not-found">
            <span>Sem viatura com marca </span> <i style="padding:.5em;">"{{ marca }}"</i> <span> no momento</span> 
            </div>                      

        </div>
    </section>

</template>
<script>

    // import { mapState } from 'vuex';
    // import FlipCard from './FlipCard.vue';

    export default {
        // components: {
        //     FlipCard,
        // },
        // computed: {
        //     ...mapState({
        //         eletrico: state => state.veiculoMarca.eletrico,
        //     }),
        // },

        data(){
            return{
                carros: [],
                }

            },
        props:{
            marca: {
                type: String,

            },
            acessorio: {
                type: Boolean,
                default: false
            }

        },
        watch:{

            marca(){
                if(this.marca !== ''){
                    
                    this.carros = this.$store.state.veiculoMarca[this.marca] || []

                    console.log(this.carros);

                }
            }

        },
        created(){
            if(this.marca !== ''){
                this.carros = this.$store.state.veiculoMarca[this.marca] || []
                
            }
        }
        
    }
    
</script>

<style scoped>
    .titulo{
        text-transform: capitalize;
    }

    .not-found{
        color: #111;
        width: 100%;
        height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        opacity: .5;


    }

    section.novos-carros {
        padding: 40px 4%;
        box-shadow: 0 0 40px 10px #ebe1e1;
    } 
    .novos-carros .carro-box{
        color: var(--cor-preto);
        padding: 20px;
        border-radius: 10px;
        margin-top: 45px;
        transition: .2s;
        text-decoration: none;

        min-height: 90%;
    } 

    .novos-carros .carro-box:hover{
        transform: scale(1.02);
        box-shadow: 0 0 8px #1115;
    }
    .novos-carros .carro-box h3{
        font-size: 28px;
        margin: 15px 0;
    } 
    .novos-carros .carro-box .img-container{
        width: 100%;
        height: 8em;        
    }
    .novos-carros .carro-box .img-container img{
        width: 90%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .btn-acessorio {
        background-color: var(--cor-vermelho);
        color: var(--cor-BRANCO);
        display: flex;
        gap: 1rem;
        max-height: 40px;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-decoration: none;
    }

    .btn-acessorio a, .btn-acessorio b {
        text-decoration: none;
        color: var(--cor-BRANCO);
        text-align: center;
        justify-content: center;
        padding: 0 3em;
    }

    .carros-eletricos {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    /* Carros eletricos */
    /* .car-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px;
    }

    .car-card {
        background: white;
        width: 250px;
        height: 350px;
        perspective: 1000px;
        margin: 10px;
        overflow: hidden; 
    }

    .car-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        cursor: pointer;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }

    .car-card:hover .car-card-inner {
        transform: rotateY(180deg);
    }

    .car-card-front, .car-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px; 
    }

    .car-card-front {
        background: var(--cor-BRANCO);
    }

    .car-card-front img {
        width: 100%;
        height: 60%;
        object-fit: cover;
    }

    .car-card-back {
        background-color: var(--cor-vermelho);
        color: white;
        transform: rotateY(180deg);
        padding: 10px;
        box-sizing: border-box;
        font-size: 0.8em; 
    }

    .car-card-back h3 {
        margin-top: 10;
        
    } */

</style>