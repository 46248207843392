<template>
<RouterView/>
<!-- <FlipCard v-for="car in eletrico" :key="car.nome" :car="car" /> -->
</template>


<script>
  import '@/style/style.css'
  import Home from '@/views/HomeView.vue';
  // import FlipCard from '@/components/FlipCard.vue';
 

  export default {
    name: 'App',
    components: {
      Home,
      // FlipCard,
    },

  //   data() {
  //   return {
  //     eletrico: [
  //       // Seus dados de carros elétricos aqui
  //     ]
  //   };
  // },
    watch: {
            '$store.state.scrollTo'(val) {
              console.log(val);
                this.$nextTick(() => {
                    const element = document.getElementById(val);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                        this.$store.state.scrollTo = '';
                    } else {
                        console.error('Elemento não encontrado:', val);
                    }
                });
            }
        },
  }

</script>

<style>

</style>
