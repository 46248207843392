<template>
   <section id="novidades" class="novos-carros">
        <div class="interface">
            <h2 class="titulo lang-pt">Nossas <span>Atualizações</span></h2>
            <h2 class="titulo lang-en" style="display: none;">Our <span>Updates</span></h2>
        </div>
        <div class="grid" >
            <div v-for="carro in carros" :key="carro.id" class="novoCarro" >
                <div class="carro-box" style="max-width: 20.5em;">
                    <div id="novidades1-content " >
                        <div class="img-container" >
                            <img :src="carro.img" :alt="carro.nome">
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <h3 class="lang-pt">{{ carro.nome }}</h3>
                            <!-- <h3 class="lang-en">LEAP MOTORS T03</h3> -->
                            <p style="padding: 0px;margin:0" class="lang-pt"><strong>Estado:</strong>{{ carro.estado }}</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Condition:</strong> New</p> -->
                            <p style="padding: 0px;margin:0"class="lang-pt"><strong>Motor:</strong> {{ carro.motor }}</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Engine:</strong> 100% electric</p> -->
                            <p v-if="carro.potencia" style="padding: 0px;margin:0" class="lang-pt"><strong>Potência:</strong> {{ carro.potencia }}</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Power:</strong> 107 HP</p> -->
                            <p style="padding: 0px;margin:0" class="lang-pt"><strong>Capacidade da bateria:</strong> 41.3 KW</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Battery capacity:</strong> 41.3 KW</p> -->
                            <p style="padding: 0px;margin:0" class="lang-pt"><strong>Autonomia:</strong> {{ carro.autonomia }}</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Range:</strong> 310km and 403 km</p> -->
                            <p style="padding: 0px;margin:0" class="lang-pt"><strong>Preço:</strong> {{ carro.preco1 ?? carro.preco }}</p>
                            <p v-if="carro.preco2" style="padding: 0px;margin:0" class="lang-pt"><strong>Preço:</strong> {{ carro.preco2 }}</p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Price:</strong> 1,500,000.00 ECV (310 km) || 1,650,000.00 ECV (403 km)</p> -->
                            <p style="padding: 0px;margin:0" class="lang-pt"><strong>Inclui um carregador doméstico</strong></p>
                            <!-- <p style="padding: 0px;margin:0" class="lang-en"><strong>Includes a home charger</strong></p> -->
                        </div>
                        
                        </div>
                </div>                        
            </div>
        </div>
    </section>
</template>
<script>
    export default {

        data(){
            return{
                carros: [
                    {
                        img: require("../assets/image/image-novidades/AIQAR.jpg"),
                        nome: 'Aiqar EQ3',
                        estado: 'Novo',
                        autonomia: '500 km',
                        motor: '100% elétrico',
                        preco1: 'AIQAR EQ3 4X4 =» A partir de 4.343.542,00 ECV',
                        preco2: 'AIQAR EQ3 4X2 =» A partir de 4.043.542,00 ECV'
                    },
                    {

                        img: require("../assets/image/image-novidades/Avatar11.jpg"),
                        nome: 'AVATAR 11',
                        autonomia: '630 km || 730km',
                        motor: '100% elétrico',
                        preco: '4.943.542,00 ECV'
                    },
                    {
                        img: require("../assets/image/image-novidades/HONDA.jpeg"),
                        nome: 'HONDA e-NS1',
                        estado: 'novo',
                        motor: '100% eletrico',
                        autonomia: '420 km, 182 CV',
                        potencia: '80',
                        camera: 'M/tras',
                        preco: 'A Partir 2.500.000,00 ECV'
                    },
                    {
                        img: require("../assets/image/image-novidades/LeapMotor-t03.jpg"),
                        nome: 'LEAP MOTORS T03',
                        estado: 'novo',
                        motor: '100% eletrico',
                        potencia: '107 CV',
                        capacidade_da_bateria: '41.3 KW',
                        autonomia: '310 km e 403 km',
                        preco: '1.500.000,00 ECV (310 km) || 1.650.000,00 ECV (403 km)',
                        carregador: 'domestico'
                    }

                ],
                }

            },
        
    }
</script>

<style scoped>

    section.novos-carros {
        padding: 40px 4%;
        box-shadow: 0 0 40px 10px #ebe1e1;
    } 
    .novos-carros .carro-box{
        color: var(--cor-preto);
        padding: 20px;
        border-radius: 10px;
        margin-top: 45px;
        transition: .2s;
        text-decoration: none;

        min-height: 90%;
    } 

    .novos-carros .carro-box:hover{
        transform: scale(1.02);
       /* box-shadow: 0 0 8px var(--cor-preto); */
        box-shadow: 0 0 8px #1115;
    }
    .novos-carros .carro-box h3{
        font-size: 28px;
        margin: 15px 0;
    } 
    .novos-carros .carro-box .img-container{
        width: 100%;
        height: 8em;
        /* background-color: red; */
        
    }
    .novos-carros .carro-box .img-container img{
        width: 90%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        /* margin: .3em; */

    }

</style>