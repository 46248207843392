import { createStore } from 'vuex'

export default createStore({
  state: {
    scrollTo: '',
    carroNovo: {
      nome: '',
      cor: '',
      fotos: [],
    },
 
    novoCarro: {
      nome: 'Kaiyi',
      cor: 'Preto',
      Preto:  [
      {
          
          img: require("../assets/carros-kaiyi/preto/images (1).jpeg")
      },
      {
          
          img: require("../assets/carros-kaiyi/preto/images (2).jpeg")
      },
      {
          
          img: require("../assets/carros-kaiyi/preto/image.jpeg")
      },
      {
          
          img: require("../assets/carros-kaiyi/preto/images.jpeg")
      },
      {
          img: require("../assets/carros-kaiyi/preto/KAIYI_X3_Pro_Gear_Shifter_ad6761629e.jpg")
      },
      {
          img: require("../assets/carros-kaiyi/preto/KAIYI_X3_Pro_Music_System_51ee04bd82.jpg")
      }
    
  ],
  Branco:  [
      {
          
          img: require("../assets/carros-kaiyi/branco/img1.jpeg")
      },
      {
          
          img: require("../assets/carros-kaiyi/branco/img2.jpeg")
      },
      {
          
          img: require("../assets/carros-kaiyi/branco/img3.jpeg")
      },
      {                   
          img: require("../assets/carros-kaiyi/branco/img4.jpeg")
      }

  ],
  Cinza: [
      {
          
          img: require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_00_car.jpeg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_02_car.jpeg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_03_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_04_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_05_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_06_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_18_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_19_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_20_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_21_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_22_car.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_25_car.jpg")
      }
  ],
  Vermelho:
  [
      {
          img : require("../assets/carros-kaiyi/vermelho/img.webp")
      },
      {
          img : require("../assets/carros-kaiyi/vermelho/kaiyi_x3_pro vermelhofrente.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/vermelho/kaiyi_x3_pro_07_tras.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/vermelho/KAIYI_X3_Pro_Dashboard_16eb0ed40d.jpg")
      },
      {
          img : require("../assets/carros-kaiyi/vermelho/KAIYI_X3_Pro_interior.jpg")
      }
  ],
  },

  selectedMarca: '',

  veiculoMarca:{
    bmw:[
        {
            img: require("../assets/image/image-veiculos//bmw//BMW X7.jpg"),
            nome: 'BMW X7 40i XDRIVE FULL EXTRAS 4X4',
            estado: 'novo',
            motor: 'Gasolina, 3940cc',
            caixa: 'Automatica 380 CV',
            carateristica: 'interior em pele',
            camera: '360º, sensores + tecto panoramico 6 lugares',
            preco: 'a partir de 18.800.000,00 ECV'
        },
    ],
    audi:[

    ],
    mitsubishi:[
       
        {
            img: require("../assets/image/image-veiculos//Tipos de Mitsubishi//Mitsubishi Outlander 4x2 7 lugares Full Extra.jpg"),
            nome: 'Mitsubishi Outlander 4x2 7 lugares Full Extra',
            estado: 'novo',
            caixa: 'Automática',
            motor: '2.5 Gasolina',
            preco: '4.850.000,00 ECV',
            camera: 'M/ Atrás'
        },
        {
            img: require("../assets/image/image-veiculos/Tipos de Mitsubishi/Mitsubishi ASX 4X4 Full Extra.png"),
            nome: 'Mitsubishi ASX 4X4 Full Extra',
            estado: 'novo',
            caixa: 'Automática com comando no volante',
            motor: 'Turbo 2.0 Gasolina',
            preco: '4.450.000,00 ECV',
            camera: 'M/ Atrás'
        },
        {
            img: require("../assets/image/image-veiculos//Tipos de Mitsubishi/Mitsubishi Pick Up L200 Cabine Simples 4X4.webp"),
            nome: 'Mitsubishi Pick Up L200 Cabine Simples 4X4',
            estado: 'novo',
            caixa: 'Manual-5 Velocidades',
            motor: '2.5 DCI Turbo Diesel',
            preco: '3.750.000,00 ECV',
            camera: 'Não'
        },
        {
            img: require("../assets/image/image-veiculos//Tipos de Mitsubishi//Mitsubishi Pick Up L200 Cabine Duplo 4X4 - Modelo Base.jpg"),
            nome: 'Mitsubishi Pick Up L200 Cabine Duplo 4X4 - Modelo Base',
            estado: 'novo',
            caixa: 'Manual-5 Velocidades + M/Atrás',
            motor: '2.5 Diesel',
            preco: '3.750.000,00 ECV',
            camera: 'Não'
        },
    ],
    tesla:[
        {
            img: require("../assets/image/image-veiculos//tesla//TESLA MODEL Y 2.jpg"),
            nome: 'Tesla Model Y (MODELO EUROPEU)',
            estado: 'novo',
            motor: '100% eletrico',
            caixa: 'Automatica 300 CV',
            autonomia: '455',
            tecto: 'Panoramico e cameraM/Tras'
        },
    ],
    jmc:[
        {
            img: require("../assets/image/image-veiculos//jmc//JMC-Vigus.jpg"),
            nome: 'Pick up JMC Vigus 4x2',
            estado: 'novo',
            transmissao: 'Caixa Manual de 5 velocidades',
            cilindrada: '2.5 cc',
            potencia: '134 kw = 182 cv',
            motor: 'FORD RANGER (com peças na Europa)',
            cabine: 'Dupla',
            preco: '3.090.000,00 ECV',
            camera: 'Sem Câmera M/tras'
        },
        {
            img: require("../assets/image/image-veiculos//jmc//JMC-Vigus.jpg"),
            nome: 'Pick up JMC Vigus 4x4',
            estado: 'novo',
            transmissao: 'Caixa Manual de 5 velocidades',
            cilindrada: '2.5 cc',
            potencia: '134 kw = 182 cv',
            motor: 'FORD RANGER (com peças na Europa)',
            cabine: 'Dupla',
            preco: '3.290.000,00 ECV',
            camera: 'Com câmera M/tras'
        },
    ],
    volkswagen:[
        {
            img: require("../assets/image/image-veiculos//Tipos de Volkswagen//VW ID.3 PURE.webp"),
                nome: 'VW ID.3 PURE',
                estado: 'novo',
                motor: '100% eletrico',
                potencia: '170 CV',
                autonomia: '420 KM',
                preco: '2.593.542,00 ECV',
                carregador: 'Domestico'
            },
            {
                img: require("../assets/image/image-veiculos//Tipos de Volkswagen//VW ID.4 CROZZ PURE +.jpeg"),
                nome: 'VW ID.4 CROZZ PURE +',
                estado: 'novo',
                motor: '100% eletrico',
                potencia: '204 CV',
                autonomia: '450 KM',
                capacidadeBateria: '77 KW/h',
                preco: '3.643.542,00 ECV',
                carregador: 'Domestico'
            },
            {
                img: require("../assets/image/image-veiculos//Tipos de Volkswagen//VW ID.4 CROZZ PRO.jpg"),
                nome: 'VW ID.4 CROZZ PRO',
                estado: 'novo',
                motor: '100% eletrico',
                potencia: '310 CV',
                autonomia: '600 KM',
                capacidadeBateria: '84.8 KW/h',
                preco: '3.843.542,00 ECV',
                carregador: 'Domestico'
            },
            {
                img: require("../assets/image/image-veiculos//Tipos de Volkswagen//VW ID.6 CROZZ PRO 7 LUGARES.jpg"),
                nome: 'VW ID.6 CROZZ PRO 7 LUGARES',
                estado: 'novo',
                motor: '100% eletrico',
                autonomia: '436 KM',
                capacidadeBateria: '85 kw/H',
                preco: '4.793.542,00 ECV',
                carregador: 'domestico'
            },
    ],
    mercedes:[
        {
            img: require("../assets/image/image-veiculos/Tipos de Mercedes/MERCEDES EQB 4MATIC 7 Lugares, Full Extra.jpg"),
            nome: 'MERCEDES EQB 4MATIC 7 Lugares, Full Extra',
            estado: 'novo',
            caixa: 'Automática',
            motor: '100% Elétrico',
            camera: 'M/Trás',
            preco: 'A partir de 5.500.000,00 ECV'
        },
        {
            img: require("../assets/image/image-veiculos//MERCEDES//MERCEDES LEMOUSINE//mercedesBenzClasseE.avif"),
            nome: 'MERCEDES-BENZ CLASSE E',
            estado: 'Por encomenda',
        },
        {
            img: require("../assets/image/image-veiculos//MERCEDES//MERCEDES LEMOUSINE//mercedesBenzClasseC.avif"),
            nome: 'MERCEDES-BENZ CLASSE C',
            estado: 'Por encomenda',
        },
        {
            img: require("../assets/image/image-veiculos///MERCEDES/MERCEDES LEMOUSINE//mercedesBenzCLACoupe.avif"),
            nome: 'MERCEDES CLA COUPÉ',
            estado: 'Por encomenda',
        },
        {
            img: require("../assets/image/image-veiculos//MERCEDES//MERCEDES SUV-COMBUSTÃO//mercedesBenzGLANovo.avif"),
            nome: 'MERCEDES GLA',
            estado: 'Por encomenda',
        },
        {
            img: require("../assets/image/image-veiculos//MERCEDES//MERCEDES SUV-COMBUSTÃO//mercedesBenzGLB.webp"),
            nome: 'MERCEDES GLB',
            estado: 'Por encomenda',
        },
        {
            img: require("../assets/image/image-veiculos//MERCEDES//MERCEDES SUV-COMBUSTÃO//mercedesBenzGLC.avif"),
            nome: 'MERCEDES GLC',
            estado: 'Por encomenda',
        },

    ],
    aiqar:[
 
        {
            img:  require("../assets/image/image-veiculos/./aiqar//AIQAR (1).jpg"),
            nome: 'Aiqar EQ3',
            estado: 'Novo',
            autonomia: '500 km',
            motor: '100% elétrico',
            preco1: 'AIQAR EQ3 4X4 =» A partir de 4.343.542,00 ECV',
            preco2: 'AIQAR EQ3 4X2 =» A partir de 4.043.542,00 ECV'
        }
    ],
    baic:[
        {
            img: require("../assets/image/image-veiculos//Tipos de Baic//Baic X35 Manual.png"),
            nome: 'BAIC X35 Manual',
            estado: 'Novo',
            caixa: 'Manual',
            motor: '1.5 Gasolina',
            preco: '2.650.000,00 ECV',
            camera: 'Não',
        },
        {
            img: require("../assets/image/page-carros/Tipos de Baic/Baic X35 Automático.png"),
            nome: 'BAIC X35 Automatico',
            estado: 'Novo',
            caixa: 'Automatico',
            motor: '1.5 Gasolina',
            preco: '2.900.000,00 ECV',
            camera: 'M/Trás + Radar de Estacionamento Traseiro'
        },
        {
            img: require("../assets/image/image-veiculos//Tipos de Baic//Baic X55 Automático Modelo 1.png"),
            nome: 'BAIC X55 Automatico x55 Automatico Modelo 1',
            estado: 'Novo',
            caixa: 'Manual',
            motor: '1.5 Gasolina Tecnologia Grupo Daimler (Mercedes-Benz)',
            preco: '3.100.000,00 ECV',
            camera: 'M/Trás + Radar de Estacionamento Traseiro'
        },
        {
            img: require("../assets/image/image-veiculos//Tipos de Baic//Baic X55 Automático Modelo 2.jpg"),
            nome: 'BAIC X55 Automatico Modelo 2',
            estado: 'Novo',
            caixa: 'Automatico',
            motor: '1.5 Gasolina Tecnologia Grupo Damier (Mercedes-Benz)',
            preco: '3.590.000,00 ECV',
            camera: 'M/Trás + Radar de Estacionamento Traseiro'
        },

    ],
    avatar:[
        {
            img: require("../assets/image/image-veiculos/./avatar//Avatar 11 (1).jpg"),
            nome: 'AVATAR 11',
            autonomia: '630 km || 730km',
            motor: '100% elétrico',
            bateria: '90 Km || 730 Km',
            preco: 'A partir de 4.943.542,00 ECV'
        },
    ],
    honda:[
        {
            img: require("../assets/image/image-veiculos//honda//honda (1).jpg"),
            nome: 'HONDA e-NS1 Modelo e-type Basic',
            estado: 'novo',
            motor: '100% eletrico',
            autonomia: '420 km, 182 CV',
            potencia: '134 kw = 182 cv',
            preco: 'A Partir 2.500.000,00 ECV'
        },
        {
            img: require("../assets/image/image-veiculos//honda//Honda ens1 az.jpg"),
            nome: 'Honda e-NS1 Modelo Confort',
            estado: 'novo',
            motor: '100% eletrico',
            autonomia: '420 km',
            potencia: '134 kw = 182 cv',
            tecto: 'Com tecto panorâmico Vidro',
            camera: 'M/tras',
            preco: 'a partir de 2.700.000,00 ECV'
        }
    ],
    geely:[
        {
            img: require("../assets/image/image-veiculos//Tipos de gelly//GEELY RADAR RD6 (pickup).jpg"),
            nome: 'GEELY RADAR RD6 (pickup)',
            estado: 'novo',
            motor: '100% eletrico',
            capacidadeBateria: '63km/h',
            autonomia: '410 km e 550 km',
            preco: '3.150.000,00 (410km) || 3.593.542,00 ECV (550 km)'
        },
    ],
    leap:[
        {
            img: require("../assets/image/image-veiculos//Tipos de leap motors//LEAP MOTORS T03.jpg"),
            nome: 'LEAP MOTORS T03',
            estado: 'novo',
            motor: '100% eletrico',
            potencia: '107 CV',
            capacidadeBateria: '41.3 KW',
            autonomia: '310 km e 403 km',
            preco: '1.500.000,00 ECV (310 km) || 1.650.000,00 ECV (403 km)',
            carregador: 'domestico'
        },
    ],
    seres:[
        {
            img: require("../assets/image/image-veiculos//Tipos de seres//SERES 3 LUXURY BY HUAWEI.jpg"),
            nome: 'SERES 3 LUXURY BY HUAWEI',
            estado: 'novo',
            motor: '100% eletrico',
            autonomia: '405 Km',
            capacidadeBateria: '63.61 kw/h',
            potencia: '163 CV',
            preco: '2.990.000,00 ECV',
            carregador: 'domestico'
        },
    ],
    skoda:[
        {
            img: require("../assets/image/image-veiculos//Tipos de skoda//SKODA ENYAQ IV (4x2).jpg"),
            nome: 'SKODA ENYAQ IV (4x2)',
            estado: 'novo',
            motor: '100% eletrico',
            capacidadeBateria: '60 kw/h',
            autonomia: '510 KM',
            potencia: '186 CV',
            preco: '5.348.542,00 ECV',
            carregador: 'domestico'
        },
    ],
    soueast:[
        {
            img: require("../assets/image/image-veiculos//Tipos de Soueast//SouEast A5.jpg"),
            nome: 'SouEast A5',
            estado: 'novo',
            caixa: 'Automática',
            motor: 'Mitsubishi 1.5 Gasólina',
            preco: '2.090.000,00 ECV',
            camera: 'Câmera de m/ atrás + sensor de estacionamento'
        },
        {
            img: require("../assets/image/image-veiculos//Tipos de Soueast//SouEast Dx5 Full Extra.png"),
            nome: 'SouEast Dx5  Full Extra',
            estado: 'novo',
            caixa: 'Automático',
            motor: 'Mitsubishi 1.5 Gasolina',
            preco: '2.900.000,00 ECV',
            camera: 'Câmera de m/ atrás + sensor de estacionamento'
        },
        {
            img: require("../assets/image/image-veiculos//Tipos de Soueast//SouEast DX8S Full Extra 7 lugares.jpg"),
            nome: 'SouEast DX8S Full Extra 7 lugares',
            estado: 'novo',
            caixa: 'Automática',
            motor: 'Mitsubishi 1.5 Gasolina',
            preco: '3.350.000,00 ECV',
            camera: 'Câmera de m/atrás + sensor de estacionamento'
        },
    ],
    
    eletrico:[
        {
            img: require("../assets/image/logo-carros/leapmotor-logo-1000x650.webp"),
            nome: 'LEAP MOTORS T03',
            estado: 'novo',
            motor: '100% eletrico',
            potencia: '107 CV',
            capacidadeBateria: '41.3 KW',
            autonomia: '310 km e 403 km',
            preco: '1.500.000,00 ECV (310 km) || 1.650.000,00 ECV (403 km)',
            carregador: 'domestico'
        },
        {
            img: require("../assets/image/logo-carros/volkswagen.jpg"),
            nome: 'VW ID.4 CROZZ PURE +',
            estado: 'novo',
            motor: '100% eletrico',
            potencia: '204 CV',
            autonomia: '450 KM',
            capacidadeBateria: '77 KW/h',
            preco: '3.643.542,00 ECV',
            carregador: 'domestico'
        },
        {
            img: require("../assets/image/logo-carros/seres.jpg"),
            nome: 'SERES 3 LUXURY BY HUAWEI',
            estado: 'novo',
            motor: '100% eletrico',
            autonomia:'405 Km',
            capacidadeBateria: '63.61 kw/h',
            potencia: '163 CV',
            preco: '2.990.000,00 ECV',
            carregador: 'domestico'
        },
        {
            img: require("../assets/image/logo-carros/skoda.jpg"),
            nome: 'SKODA ENYAQ IV (4x2)',
            estado: 'novo',
            motor: '100% eletrico',
            capacidadeBateria: '60 kw/h',
            autonomia: '510 KM',
            potencia: '186 CV',
            preco: '5.348.542,00 ECV',
            carregador: 'domestico'
        },
        {
            img: require("../assets/image/logo-carros/geely.jpg"),
            nome: 'GEELY RADAR RD6 (pickup)',
            estado: 'novo',
            motor: '100% eletrico',
            capacidadeBateria: '63km/h',
            autonomia: '410 km e 550 km',
            preco: '3.150.000,00 (410km) || 3.593.542,00 ECV (550 km)'
        },
        {
            img: require("../assets/image/logo-carros/mercedes.jpg"),
            nome: 'Mercedes-Benz EQC',
            estado: 'novo',
            motor: '100% eletrico',
            autonomia: '400 KM',
            potencia: '408 CV',
            preco: '5.500.000,00 ECV'
        },
    ],
    
    luxo:[
        {
            img: require("../assets/image/image-veiculos/MERCEDES/MERCEDES LEMOUSINE/mercedesBenzCLACoupe.avif"),
            nome: 'Mercedes-Benz',
            estado: 'novo',
            modelosDisponiveis: 'Classe S, AMG GT, EQS',
            preco: 'A partir de 12.000.000,00 ECV',
        },
        {
            img: require("../assets/image/image-veiculos/bmw/BMW X7.jpg"),
            nome: 'BMW',
            estado: 'novo',
            modelosDisponiveis: 'Série 7, X7, i8',
            preco: 'A partir de 10.500.000,00 ECV',
        },
        {
            img: require("../assets/image/image-veiculos/avatar/Avatar 11 (1).jpg"),
            nome: 'Avatar',
            estado: 'novo',
            modelosDisponiveis: 'Avatar 11, Avatar E-TYPE',
            preco: 'A partir de 11.000.000,00 ECV',
        },
        {
            img: require("../assets/image/image-veiculos/audi/top-10-audi.webp"),
            nome: 'Audi',
            estado: 'novo',
            modelosDisponiveis: 'A8, Q8, e-tron GT',
            preco: 'A partir de 11.500.000,00 ECV',
        },
    ],
    acessorio:[
        {
            img: require("../assets/image/image-acessorios/tapetes.jpeg"),
            nome: 'Tapetes',
        },
        {
            img: require("../assets/image/image-acessorios/volantes.jpeg"),
            nome: 'Volantes',
        },
        {
            img: require("../assets/image/image-acessorios/rodas.webp"),
            nome: 'Rodas',
            botao: 'Enviar Email',
        },
        {
            img: require("../assets/image/image-acessorios/pneus.webp"),
            nome: 'Pneus',
        },
        {
            img: require("../assets/image/image-acessorios/espelhos.jpeg"),
            nome: 'Espelhos',
        },
        {
            img: require("../assets/image/image-acessorios/paraChoques.jpeg"),
            nome: 'Para-Choques',
        },
        {
            img: require("../assets/image/image-acessorios/paraBrisas.jpg"),
            nome: 'Para-Brisas',
        },

    ],






  },

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
