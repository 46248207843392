<template>
 <section id="contato" class="formulario">
    <!--
        conta getform
        Nome: FREEXAUTO
        Email: Standfreexauto@gmail.com
        pass: Senh@123freexauto
        -->
        <h2 class="titulo lang-pt">Entre em <span >Contato</span></h2> 
        <h2 class="titulo lang-en" style="display: none;">Contact <span>Us</span></h2>
    <div class="interface">
        <div class="fundoContato">
            <img src="../assets/image/fundoContato.jpg" alt="contacto">
        </div>    
            <div class="formulario-container">     
                <form action="https://getform.io/f/warkdoyb" method="POST">
                    <input class="lang-pt" type="text" name="name" id="name" placeholder="Insira o seu nome" required>
                    <input class="lang-en" type="text" name="name" id="name" placeholder="Enter your name" required>
                    <input class="lang-pt" type="text" name="email" id="email" placeholder="Insira o seu email" required>
                    <input class="lang-en" type="text" name="email" id="email" placeholder="Enter your email" required>
                    <input class="lang-pt" type="text" name="phone" id="phone" placeholder="Insira o seu número de telemóvel">
                    <input class="lang-en" type="text" name="phone" id="phone" placeholder="Enter your mobile phone number">
                    <textarea class="lang-pt" name="message" id="message" placeholder="Sua mensagem"></textarea>
                    <textarea class="lang-en" name="message" id="message" placeholder="Your Message"></textarea>
            
                    <div class="btn-enviar"><input class="lang-pt" type="submit" value="ENVIAR">
                                            <input class="lang-en" type="submit" value="SEND">
                    </div>
                </form>
            </div> 

            <div class="mapa">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3855.2165849826843!2d-23.4954496!3d14.925021999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935996563ada905%3A0xd754b08dd992138f!2sParque%20Tecnol%C3%B3gico%20Arquip%C3%A9lago%20Digital%20de%20Cabo%20Verde%20-%20TECHPARK.CV!5e0!3m2!1spt-PT!2scv!4v1713357822800!5m2!1spt-PT!2scv" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div> 
    </div>
</section> 
 </template>
 <script>
 export default {
     
 }
 </script>
 <style>
     
 </style>