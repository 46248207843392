<template>
    <section id="veiculos" class="veiculos">
         <div class="interface">
            <h2 class="titulo lang-pt">Nossos <span>Veículos</span></h2>
            <h2 class="titulo lang-en" style="display: none;">Our <span>Updates</span></h2>
        </div>
        <div class="container-veiculos">
            <div @click="selectMarca(veiculo.nome)" v-for="veiculo in veiculos.filter(v => !v.novo)" :key="veiculo.id" class="carro">
                <img :src="veiculo.imagem">

            </div>
        </div>
        <div class="container-veiculo">
            <div @click="selectMarca(veiculo.nome)" v-for="veiculo in veiculos.filter(v => v.novo)" :key="veiculo.id" class="item-veiculo">
              <img :src="veiculo.imagem">
              <b class="lang-pt"> {{ veiculo.nome }} </b>
            </div>
        </div>



        <!-- <div class="container-veiculo">
            <div @click="selectMarca(veiculo.nome)" v-for="veiculo in veiculos.filter(v => v.novo)" :key="veiculo.id" class="item-veiculo">
              <img :src="veiculo.imagem">
              <b class="lang-pt"> {{ veiculo.nome }} </b>
              <div class="btn-acessorio">  
                  <a class="lang-pt"  target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=Standfreexauto@gmail.com&su=Informações sobre os acessórios">
                      <i class="bi bi-envelope"></i>
                      Enviar Email
                  </a>  
              </div>
            </div>
        </div> -->
    </section>
 </template>
 <script>


export default {
  data() {
    return {
      veiculos: [
      { nome: 'audi', imagem: require('@/assets/image/logo-carros/audi.jpg') },
      { nome: 'aiqar', imagem: require('@/assets/image/logo-carros/AIQAR.png') },
      { nome: 'avatar', imagem: require('@/assets/image/logo-carros/AVATAR.png') },
      { nome: 'baic', imagem: require('@/assets/image/logo-carros/BAIC.jpg') },
      { nome: 'bmw', imagem: require('@/assets/image/logo-carros/BMW.png') },
      { nome: 'geely', imagem: require('@/assets/image/logo-carros/geely.jpg') },
      { nome: 'honda', imagem: require('@/assets/image/logo-carros/HONDA (1).jpg') },
      { nome: 'jmc', imagem: require('@/assets/image/logo-carros/jmc.jpg') },
      { nome: 'leap', imagem: require('@/assets/image/logo-carros/leapMotors.png') },
      { nome: 'mercedes', imagem: require('@/assets/image/logo-carros/mercedesBenz.jpg') },
      { nome: 'mitsubishi', imagem: require('@/assets/image/logo-carros/mitsubishi.jpg') },
      { nome: 'seres', imagem: require('@/assets/image/logo-carros/seres.jpg') },
      { nome: 'skoda', imagem: require('@/assets/image/logo-carros/skoda.jpg') },
      { nome: 'smart', imagem: require('@/assets/image/logo-carros/smart.jpg') },
      { nome: 'soueast', imagem: require('@/assets/image/logo-carros/soueast.jpg') },
      { nome: 'tesla', imagem: require('@/assets/image/logo-carros/tesla.jpg') },
      { nome: 'volkswagen', imagem: require('@/assets/image/logo-carros/volkswagen.jpg') },
      { nome: 'eletrico', imagem: require('@/assets/image/logo-carros/carrosEletrico.jpg'), novo: true },
      { nome: 'luxo', imagem: require('@/assets/image/logo-carros/carrosLuxo.jpg'), novo: true },
      { nome: 'acessorio', imagem: require('@/assets/image/logo-carros/acessorios.jpg'), novo: true },

      // {imagem: require('@/assets/image/logo-carros/carrosEletrico.jpg')},
      // {imagem: require('@/assets/image/logo-carros/carrosLuxo.jpg')},
      // {imagem: require('@/assets/image/logo-carros/acessorios.jpg')},
      ]
    }
  },
  methods: {


    selectMarca(marca) {
      this.$store.state.selectedMarca = marca;
      this.$store.state.scrollTo = 'marca';
      console.log(this.$store.state.scrollTo);
    },

    // enviarEmail(nomeAcessorio) {
    //   const email = "https://mail.google.com/mail/?view=cm&fs=1&to=Standfreexauto@gmail.com&su=Informações sobre os acessórios";
    //   const assunto = encodeURIComponent(`Inquérito sobre ${nomeAcessorio}`);
    //   const corpoEmail = encodeURIComponent(`Olá,\n\nEstou interessado(a) em mais informações sobre: ${nomeAcessorio}. Por favor, poderia me fornecer mais detalhes?\n\nObrigado.`);
    //   window.location.href = `mailto:${email}?subject=${assunto}&body=${corpoEmail}`;
    // },
  }
}
 </script>
 <style scoped>

  img{
    cursor: pointer;
  }

  .container-veiculo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4em;
    padding: 1em;
    padding-top: 2em;
  }

  .item-veiculo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
     
 </style>