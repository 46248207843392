<template>
    <header>
        <div class="interface">
            <div class="logo">
                <a @click="()=> fromHome ? scrollToSection('barraLateralKaiyi') : gotToPath('/','barraLateralKaiyi') ">
                    <img src="../assets/logo.png" alt="Logo">
                </a>
            </div>

            <nav class="menu-desktop">
                <ul>
                    <!-- <li class="lang-pt"><a href="#empresa">Empresa</a></li>
                    <li class="lang-en"><a href="#empresa">Enterprise</a></li>
                    <li class="lang-pt"><a href="#novidades">Novidades</a></li>
                    <li class="lang-en"><a href="#novidades">Novelties</a></li>
                    <li class="lang-pt"><a href="#servicos">Serviços</a></li>
                    <li class="lang-en"><a href="#servicos">Services</a></li>
                    <li class="lang-pt"><a href="#veiculos">Veículos</a></li>
                    <li class="lang-en"><a href="#veiculos">Vehicles</a></li>
                    <li class="lang-pt"><a href="#contato">Contato</a></li>
                    <li class="lang-en"><a href="#contato">Contact</a></li> -->
                    <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('empresa')">Empresa</a>
                            <a v-else @click="gotToPath('/','empresa')">Empresa</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('novidades')">Novidades</a>
                            <a v-else @click="gotToPath('/','novidades')">Novidades</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('servicos')">Serviços</a>
                            <a v-else @click="gotToPath('/','servicos')">Serviços</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('veiculos')">Veículos</a>
                            <a v-else @click="gotToPath('/','veiculos')">Veículos</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('contato')">Contato</a>
                            <a v-else @click="gotToPath('/','contato')">Contato</a>
                        </li>
                </ul> 
                
                <!-- <div class="lang-switch">
                    <button onclick="switchLanguage('pt')">PT</button>
                    <button onclick="switchLanguage('en')">EN</button>
                </div> -->

                <div class="login" id="btn-login">
                    <Icon class="icon-perfil" icon="bi:person-circle" />
                    <!-- <a href="login.php"><i class="bi bi-person-circle"></i></a> -->
                </div>       
            </nav>     
                   
            <div class="btn-abrir-menu" id="btn-menu">
                <i class="bi bi-list"></i>
            </div> 

            <div class="menu-mobile" id="menu-mobile">
                <div class="btn-fechar">
                    <i class="bi bi-x-lg"></i>  
                </div>

                <!-- <div class="lang-switch">
                    <button @click="switchLanguage('pt')">PT</button>
                    <button @click="switchLanguage('en')">EN</button>
                </div> -->

                <nav>
                    <ul>
                        <!-- <li class="lang-pt"><a href="#empresa">Empresa</a></li>
                        <li class="lang-en"><a href="#empresa">Enterprise</a></li>
                        <li class="lang-pt"><a href="#novidades">Novidades</a></li>
                        <li class="lang-en"><a href="#novidades">Novelties</a></li>
                        <li class="lang-pt"><a href="#servicos">Serviços</a></li>
                        <li class="lang-en"><a href="#servicos">Services</a></li>
                        <li class="lang-pt"><a href="#veiculos">Veículos</a></li>
                        <li class="lang-en"><a href="#veiculos">Vehicles</a></li>
                        <li class="lang-pt"><a href="#contato">Contato</a></li>
                        <li class="lang-en"><a href="#contato">Contact</a></li>
                        <li><a href="login.php">Login</a></li> -->
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('empresa')">Empresa</a>
                            <a v-else @click="gotToPath('/','empresa')">Empresa</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('novidades')">Novidades</a>
                            <a v-else @click="gotToPath('/','novidades')">Novidades</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('servicos')">Serviços</a>
                            <a v-else @click="gotToPath('/','servicos')">Serviços</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('veiculos')">Veículos</a>
                            <a v-else @click="gotToPath('/','veiculos')">Veículos</a>
                        </li>
                        <li class="lang-pt">
                            <a v-if="fromHome"  @click="scrollToSection('contato')">Contato</a>
                            <a v-else @click="gotToPath('/','contato')">Contato</a>
                        </li>


                        
                    </ul>

                </nav>
            </div>

          <div class="overlay-menu" id="overlay-menu"></div>
        </div> 
    </header>
</template>

<script>
    export default {
        name: 'Header',
        data: () => {
            return {
                lang: 'pt',
            }
        },
        props: {
            fromHome: {
                type: Boolean,
                default: true
            }
        },
        methods: {

            gotToPath(path, id = null) {
                this.$router.push(path).then(() => {
                    this.$nextTick(() => {
                        if (id != null) {
                            this.scrollToSection(id);
                        }
                    });
                });
            },

            scrollToSection(id) {
                document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
            },

            switchLanguage(lang) {
                let elements = document.querySelectorAll('.lang-pt, .lang-en');
                elements.forEach(element => {
                    if (element.classList.contains('lang-' + lang)) {
                        element.style.display = 'block';
                    } else {
                        element.style.display = 'none';
                    }
                });
            }
        }
    }
</script>