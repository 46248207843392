<template>
    <section id="servicos" class="especialidades">    
        <div class="interface">
            <h2 class="titulo lang-pt">Nossos <span>Serviços</span></h2>
            <h2 class="titulo lang-en">Our <span>Services</span></h2>
        </div>
        <div class="grid" style="display: flex;justify-content: center;">
            <div v-for="servico in servicos" :key="servico.id"  class="especialidades-box">
                <div id="especialidades-content">
                    <div class="img-container">
                        <img :src="servico.img" :alt="servico.nome">
                    </div>
                    <h3 class="lang-pt">{{servico.nome}}</h3>
                    <h3 class="lang-en">Vehicle sales</h3>
                </div>
            </div>  
        </div>      
    </section>
 </template>
<script>
export default {

    data(){
        return{
            servicos: [
                {
                    
                    img: require("../assets/image/image-servicos/venda-de-carro.webp"),
                    nome: 'Vendas de viaturas'
                },
                {
                    
                    img: require("../assets/image/image-servicos/vendasDeAcessorios.webp"),
                    nome: 'Venda de peças e Acessórios'
                },
                {
                    
                    img: require("../assets/image/image-servicos/posVenda.jpg"),
                    nome: 'Assistência Pós-venda'
                },
                {
                    img: require("../assets/image/image-servicos/manuntencaoReparacao.jpg"),
                    nome: 'Manutenção e Reparação'
                },
                {
                    img: require("../assets/image/image-servicos/Bate-Chapa-Pintura.jpeg"),
                    nome: 'Bate Chapa e Pintura'
                }
            ],

            }

        },
    
}
</script>

 <style scoped>

    section.especialidades .grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }    
    section.especialidades {
        padding: 40px 4%;
        flex: 1; 

    } 
    .especialidades .especialidades-box{
        color: var(--cor-preto);
        padding: 2em;
        border-radius: 10px;
        transition: .2s;
        text-decoration: none;
        width: 15em;

    } 
    .especialidades .especialidades-box:hover{
        transform: scale(1.02);
        box-shadow: 0 0 8px #1115;
    }

    .especialidades .especialidades-box h3{
        font-size: 1.2em;
    } 

    .especialidades .especialidades-box .img-container{
        width: 100%;
        height: 8em;
    }

    .especialidades .especialidades-box img{
        width: 100%;
        object-fit: cover;
    } 
     
 </style>