<template>
    <Header/>
    <Hero/>
    <Slide/>
    <Empresa/>
    <Novidades/>
    <Servicos/>
    <Veiculos/>
    <MarcasViatura :marca="$store.state.selectedMarca" v-if="$store.state.selectedMarca" />
    <Contato/>
    <Footer/>
    </template>
    
    
    <script>
      import '@/style/style.css'
      import Header from '@/components/Header.vue';
      import Hero from '@/components/Hero.vue';
      import Slide from '@/components/Slide.vue';
      import Empresa from '@/components/Empresa.vue';
      import Novidades from '@/components/Novidades.vue';
      import Servicos from '@/components/Servicos.vue';
      import Veiculos from '@/components/Veiculos.vue';
      import Contato from '@/components/Contato.vue';
      import Footer from '@/components/Footer.vue';
      import MarcasViatura from '@/components/MarcasViatura.vue';
    
      export default {
        name: 'HomeView',
       

        methods: {
            changeLang(lang){
                this.$i18n.locale = lang;
            },
        },

        watch: {
            '$store.state.scrollTo'(val) {
                this.$nextTick(() => {
                    const element = document.getElementById(val);
                    this.$store.state.scrollTo = ''
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        console.error('Elemento não encontrado:', val);
                    }
                });
            }
        },
    

        components: {
          Header,
          Hero,
          Slide,
          Empresa,
          Novidades,
          Servicos,
          Veiculos,
          MarcasViatura,
          Contato,
          Footer
    
        }
      }
    
    </script>
    
    <style>
    
    </style>