<template>
    <div id="barraLateralKaiyi" class="barra-lateral-kaiyi">
        <!-- <RouterLink to="/novidade?" class="cor-kaiyi"> -->
            <img style="cursor: pointer;" @click="openPopUp=true" src="../assets/image/image-veiculos/kaiyi//Kaiyi-Logo.png" alt="Logo Kaiyi" class="logo-kaiyi">
        <!-- </RouterLink> -->

        <div class="info-kaiyi">
            <span><p class="lang-pt">Garantia 5 anos - 150.000km</p></span>
            <span><p class="lang-en">5 year warranty - 150,000km</p></span>
            <span><p class="lang-pt">Representante Oficial</p></span>
            <span><p class="lang-en">Official Representative</p></span>
            <img style="cursor: pointer;" @click="openPopUp=true" src="../assets/image/image-veiculos//kaiyi//kaiyi-x3Pro.jpg" alt="Carro Kaiyi">
            <div class="overlay-text">
                <p class="lang-pt" style="cursor: pointer;" @click="openPopUp=true" >Clique para ver mais carros da marca em outras cores</p>
                <p class="lang-en">Click to see more of the brand's cars in other colors</p>
            </div>
        </div>


        <section class="pop-up" v-if="openPopUp">
            <div style="position: relative;max-width: 60vw;max-height: 80vh;" id="modalKaiyi" class="modal-kaiyi">
                <div class="modal-content">
                    <span @click="openPopUp=false" class="close">&times;</span>
                    <!-- <img id="imagemCarroKaiyi" src="@/assets/image/image-veiculos//kaiyi//kaiyi-x3Pro preto.png" alt="Carro Kaiyi')"> -->
                    <RouterLink :to="'/novidade?cor='+selectedColor" >
                        <img id="imagemCarroKaiyi" :src="selectedImg" alt="Carro Kaiyi')">
                    </RouterLink>
                    
                    <span class="ver-mais"> 
                        <RouterLink :to="'/novidade?cor='+selectedColor" > Ver {{ nome }} {{selectedColor}}</RouterLink>
                    </span>

                    <div class="cores-carro">
                        <div v-for="(cor, index) in cores" :key="index" @click="setColor(cor)" :style="{backgroundColor: cores_en[index]}" ></div>
                        <!-- <div class="vermelho" @click="setColor('vermelho')" ></div>
                        <div class="preto" @click="setColor('preto' )" ></div>
                        <div class="cinza" @click="setColor('cinza' )" ></div>
                        <div class="branco" @click="setColor('branco')" ></div>  -->
                    </div>
                </div>
            </div>
            <!-- <section class="car">
                <img src="" alt="">
                <footer>
                    <ul>
                        <li>branco</li>
                        <li>cinza</li>
                        <li>preto</li>
                    </ul>
                </footer>
            </section> -->
        </section>

    </div>

</template>
<script>
import { RouterLink } from 'vue-router';

export default {
    data: () =>  {
        return {
            nome: '',
            openPopUp: false,
            selectedColor: 'preto',
            selectedImg2: require("../assets/image/image-veiculos/kaiyi/kaiyi-x3Pro preto.png"),
            cores: ['preto','branco','cinza','vermelho'],
            cores_en: ['black','white','gray','red']
            // novoCarro: {
            //     nome: 'Kaiyi',
            //     cor: 'Preto',
            //     Preto:  [
            //     {
                    
            //         img: require("../assets/carros-kaiyi/preto/images (1).jpeg")
            //     },
            //     {
                    
            //         img: require("../assets/carros-kaiyi/preto/images (2).jpeg")
            //     },
            //     {
                    
            //         img: require("../assets/carros-kaiyi/preto/image.jpeg")
            //     },
            //     {
                    
            //         img: require("../assets/carros-kaiyi/preto/images.jpeg")
            //     },
            //     {
            //         img: require("../assets/carros-kaiyi/preto/KAIYI_X3_Pro_Gear_Shifter_ad6761629e.jpg")
            //     },
            //     {
            //         img: require("../assets/carros-kaiyi/preto/KAIYI_X3_Pro_Music_System_51ee04bd82.jpg")
            //     }
              
            // ],
            // Branco:  [
            //     {
                    
            //         img: require("../assets/carros-kaiyi/branco/img1.jpeg")
            //     },
            //     {
                    
            //         img: require("../assets/carros-kaiyi/branco/img2.jpeg")
            //     },
            //     {
                    
            //         img: require("../assets/carros-kaiyi/branco/img3.jpeg")
            //     },
            //     {                   
            //         img: require("../assets/carros-kaiyi/branco/img4.jpeg")
            //     }
          
            // ],
            // Cinza: [
            //     {
                    
            //         img: require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_00_car.jpeg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_02_car.jpeg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_03_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_04_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_05_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_06_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_18_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_19_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_20_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_21_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_22_car.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/cinza/kaiyi_x3_pro_25_car.jpg")
            //     }
            // ],
            // Vermelho:
            // [
            //     {
            //         img : require("../assets/carros-kaiyi/vermelho/img.webp")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/vermelho/kaiyi_x3_pro vermelhofrente.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/vermelho/kaiyi_x3_pro_07_tras.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/vermelho/KAIYI_X3_Pro_Dashboard_16eb0ed40d.jpg")
            //     },
            //     {
            //         img : require("../assets/carros-kaiyi/vermelho/KAIYI_X3_Pro_interior.jpg")
            //     }
            // ],
            // },
            
        }
    
    },
    computed:{


        selectedImg(){
            
            return require(`@/assets/image/image-veiculos/kaiyi/kaiyi-x3Pro ${this.selectedColor}.png`)
        }
    },
    methods:{

        setColor(cor){
            this.selectedColor = cor
        }

    },
    created(){
        this.nome = this.$store.state.novoCarro.nome
        // this.$store.state.carroNovo.nome = this.novoCarro.nome;
        // this.$store.state.carroNovo.cor = this.novoCarro.cor;
        // this.$store.state.carroNovo.fotos = this.novoCarro.Preto;
    }
}
</script>
<style scoped>
    .pop-up{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
        padding: 1em;


        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cores-carro{
        display: flex;
        gap: .5em;
        padding: 1em;
    }

    .cores-carro div{
        cursor: pointer;
        padding: 1em;
        border: 1px solid #c2c2c2;
        border-radius: .5em;
    }

    .ver-mais{
        text-align: end;
        padding-right: 10%;
        width: 100%;
        color: #111;

    }
    
    .ver-mais a{
        text-decoration: none;

        padding: .5em;
        border-radius: .5em;

        background-color: #1119;
        color: #fff;
        animation: showOff 1s normal alternate;
    }

    

</style>